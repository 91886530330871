import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "data/api/v1/endpoints.ts";
import { APIErrorResponse, baseQueryWithRedirect, UntransformedResponse } from "features/api/utils/apiUtils.ts";
import { ProductViewModel, ModificationViewModel } from "data/api/v1";
import { PostOrdersRequestBody } from "types/api/orders/postOrders";
import { ImportOrderResponse, ImportOrderRequestBody, ImportOrderResponseData } from "types/api/orders/importOrder";
import {
	ValidateProductsRequestBody,
	ValidateProductsResponse,
	ValidateProductsResponseData
} from "types/api/products/postValidate";
import { v4 as uuidv4 } from "uuid";
import {
	BillTosShipTosRequestBody,
	BillTosShipTosResponse,
	BillTosShipTosResponseData
} from "types/api/accounts/billTosShipTos.ts";

export interface BillToShipToBody {
	accountId: string;
	billToId: string;
}

export interface GetModificationsParams {
	productId: string;
	productLineId: string;
}

export const newOrderApiSlice = createApi({
	reducerPath: "newOrderAPI",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		uploadCSV: builder.mutation<ImportOrderResponseData, ImportOrderRequestBody>({
			query: (csvUpload: ImportOrderRequestBody) => ({
				url: endpoints.newOrder.csvUpload(),
				method: "POST",
				formData: true,
				body: csvUpload
			}),
			transformResponse: (response: ImportOrderResponse) => {
				if (!response.data) return;
				return {
					...response.data,
					configurations: response.data?.configurations?.map((config) => ({
						...config,
						configurationId: uuidv4()
					}))
				};
			},
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		validateOrderRequest: builder.mutation<ValidateProductsResponseData, ValidateProductsRequestBody>({
			query: (orderRequest: ValidateProductsRequestBody) => ({
				url: endpoints.newOrder.validateOrderRequest(),
				method: "POST",
				dataType: "json",
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				},
				body: orderRequest
			}),
			extraOptions: {
				handle502Error: true
			},
			transformResponse: (response: ValidateProductsResponse) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		createOrder: builder.mutation({
			query: (order: PostOrdersRequestBody | undefined) => ({
				url: endpoints.newOrder.postNewOrder(),
				method: "POST",
				dataType: "json",
				body: order
			}),
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		newOrderShipToAddresses: builder.mutation<BillTosShipTosResponseData, BillTosShipTosRequestBody>({
			query: (accountIds: BillTosShipTosRequestBody | undefined) => ({
				url: endpoints.newOrder.getBillToShipTosNewOrders(),
				method: "POST",
				dataType: "json",
				body: accountIds
			}),
			transformResponse: (response: BillTosShipTosResponse) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		getLineItems: builder.query<ProductViewModel[], string>({
			query: (productLineCode: string) => endpoints.newOrder.getProductsByProductLineCode(productLineCode),
			transformResponse: (response: { data: ProductViewModel[] }) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		getModifications: builder.query<ModificationViewModel[], object>({
			query: (args: GetModificationsParams) => {
				const { productId, productLineId } = args;
				return {
					url: endpoints.newOrder.getModifications(productId, productLineId)
				};
			},
			transformResponse: (response: { data: ModificationViewModel[] }) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		})
	})
});

export const {
	useCreateOrderMutation,
	useUploadCSVMutation,
	useValidateOrderRequestMutation,
	useNewOrderShipToAddressesMutation,
	useGetLineItemsQuery,
	useGetModificationsQuery
} = newOrderApiSlice;
