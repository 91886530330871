import {
	AccountBillToDialogFormContainer,
	AccountBillToDialogTitle,
	AllFieldsText,
	HeaderTitleStyles,
	StartOverContinueContainer
} from "./NewOrders.styles";
import { Content } from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles.ts";
import { AllFieldsRequiredText, ContinueButtonText, SelectAccountAndBillingText, StartOverText } from "constants/text";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import AccountBillToContent from "./AccountBillToContent";
import { getGroupedConfigurations, useCheckForBillToAccountErrors } from "components/NewOrders/utils/NewOrderUtils.tsx";

interface AccountBillToDialogFormProps {
	handleFindOriginalOrder: () => void;
	handleModalContinue: () => void;
}

const AccountBillToDialogForm = ({ handleFindOriginalOrder, handleModalContinue }: AccountBillToDialogFormProps) => {
	const csvContents = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const checkForErrors = useCheckForBillToAccountErrors(handleModalContinue);

	return (
		<AccountBillToDialogFormContainer>
			<HeaderTitleStyles>
				<AccountBillToDialogTitle>{SelectAccountAndBillingText}</AccountBillToDialogTitle>
				<AllFieldsText className="body2">{AllFieldsRequiredText}</AllFieldsText>
			</HeaderTitleStyles>
			<Content>
				{csvContents &&
					Object.entries(getGroupedConfigurations([csvContents])).map(([productLine, CSVs], index) => (
						<AccountBillToContent
							key={productLine}
							csvContents={CSVs}
							productLine={productLine}
							index={index}
						/>
					))}

				<StartOverContinueContainer>
					<Button
						onClick={handleFindOriginalOrder}
						variant="outlined"
						fullWidth
					>
						{StartOverText}
					</Button>

					<Button
						onClick={checkForErrors}
						variant="contained"
						fullWidth
					>
						{ContinueButtonText}
					</Button>
				</StartOverContinueContainer>
			</Content>
		</AccountBillToDialogFormContainer>
	);
};

export default AccountBillToDialogForm;
