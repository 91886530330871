import {
	NewOrdersLayoutContainer,
	NewOrdersContentContainer,
	NewOrdersContent,
	NewOrderConfirmationOrderListHeader,
	NewOrdersSummaryDetails,
	NewOrderNumberHeader,
	AddOnOriginalOrderNumber
} from "components/NewOrders/NewOrders.styles";
import {
	NewOrdersHeaderSummaryDetailsAccessoriesCount,
	NewOrdersHeaderSummaryDetailsCabinetsCount,
	NewOrdersHeaderSummaryDetailsCubeCount,
	NewOrdersHeaderSummaryDetailsProductLines,
	NewOrderThanksSubheader,
	NewOrderButton,
	NewOrderConfirmationOrderNumber,
	NewOrderConfirmationOrderPending,
	NewOrderOrderDate,
	NewOrderNewOrderLabel
} from "components/NewOrders/constants";
import ReviewOrderDetails from "components/NewOrders/Review/ReviewOrderDetails";
import ReviewOrderList from "components/NewOrders/Review/ReviewOrderList";
import ConfirmationThanks from "components/Common/ConfirmationThanks/ConfirmationThanks";
import { ReplacementHeaderText } from "components/Replacements/ReplacementHeaderText.styles";
import { useMemo, useEffect } from "react";
import { todayDate, getNewOrderSummaryDetailInfo } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "stores/application.store";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import { dateFormatTwoDigitDays } from "utils/date";
import { AddOnsProps } from "../BuildOrderPage/BuildOrderPage";
import { AddOnOrder, OriginalOrderText } from "constants/text";
import { useParams } from "react-router-dom";
import useInitOrderDetail from "hooks/useInitOrderDetail";

const NewOrderConfirmation = ({ isAddOn }: AddOnsProps) => {
	const dispatch = useDispatch();
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const summaryDetails = useMemo(() => {
		return getNewOrderSummaryDetailInfo(parsedCSV, validatedOrderResponse);
	}, [parsedCSV, validatedOrderResponse]);

	useEffect(() => {
		window.onpopstate = function () {
			window.history.replaceState(null, "New Order Confirmation", "/new-order/confirmation");
			setTimeout(function () {
				window.location.replace("/new-order");
			}, 0);
			dispatch(newOrderActions.clearNewOrder());
			dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		};
	}, [dispatch]);

	const param = useParams();
	const { orderDetail } = useInitOrderDetail(param.orderId);

	return (
		<NewOrdersLayoutContainer>
			<ConfirmationThanks
				subheader={NewOrderThanksSubheader}
				button={NewOrderButton}
			/>
			<NewOrdersContentContainer>
				<NewOrdersContent>
					<NewOrderConfirmationOrderListHeader>
						<div>{isAddOn ? AddOnOrder : NewOrderNewOrderLabel}</div>
						<div>
							<span data-testid="new-order-confirmation-header">
								<NewOrderNumberHeader>
									{isAddOn && (
										<AddOnOriginalOrderNumber>
											{OriginalOrderText} # {orderDetail.orderNumber}
										</AddOnOriginalOrderNumber>
									)}
								</NewOrderNumberHeader>
							</span>

							<NewOrdersSummaryDetails
								data-testid="new-orders-header-summary-details"
								isConfirmation={true}
							>
								<div>
									<div>{NewOrderOrderDate}</div>
									<div data-testid="new-order-header-summary-details-order-date">
										{dateFormatTwoDigitDays(String(todayDate))}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsProductLines}</div>
									{summaryDetails.productLines
										.split(",")
										.filter((value, index, productLines) => productLines.indexOf(value) === index)
										.map((productLine, index) => (
											<div
												data-testid="new-orders-header-summary-details-product-lines"
												key={index + 1}
											>
												{productLine}
											</div>
										))}
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsCabinetsCount}</div>
									<div data-testid="new-order-header-summary-details-cabinets-count">
										{summaryDetails.cabinetCount}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsAccessoriesCount}</div>
									<div data-testid="new-order-header-summary-details-accessories-count">
										{summaryDetails.accessoryCount}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsCubeCount}</div>
									<div data-testid="new-order-header-summary-details-cubes-count">
										{summaryDetails.cubeCount}
									</div>
								</div>
							</NewOrdersSummaryDetails>
						</div>
					</NewOrderConfirmationOrderListHeader>
					<ReviewOrderDetails />
					<ReviewOrderList isConfirmationPage />
				</NewOrdersContent>
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default NewOrderConfirmation;
