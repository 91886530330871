import {
	AddLineItemContainer,
	AddLineItemButtonWrapper,
	AddLineItemInputFormControl,
	AddLineItemStandardContainer,
	AddLineItemStandardDimensions
} from "./AddLineItemStyles.ts";
import { Button } from "@mui/material";
import { SyntheticEvent, useEffect, useMemo, useState, useRef } from "react";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { useGetLineItemsQuery } from "features/api/newOrderApi.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema.ts";
import {
	AddLineEnterText,
	AddLineItemHeader,
	AddLineItemProductPlaceholder,
	AddLineItemQuantityPlaceholder,
	AddLineItemRequired,
	AddLineCancelButton,
	AddLineAddAnotherItemButton,
	AddLineSaveButton,
	AddLineAddAnotherTextOne,
	SelectRequiredDimensions,
	RequiredDimensionError,
	AddLineItemStandardDimensionsHeader,
	AddLineDepthLabel,
	AddLineHeightLabel,
	AddLineWidthLabel,
	AddLineItemInvalidProductError
} from "./constants.ts";
import { models } from "types/api/viewModels.ts";
import { calculateIntervals } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { DimensionContainer, RequiredDimensionsValues } from "../RequiredDimensions/RequiredDimensionsStyles.ts";
import AddModification from "../AddModification/AddModification.tsx";
import { getFormFieldProps } from "utils/form.tsx";
import { stringArraySortAlphabetically } from "utils/array.ts";

interface AddNewLineItemModalProps {
	addItemVisibility(groupIndex?: number, configIndex?: number): void;
	configuration: models["PendingOrderConfigurationViewModel"];
	index: number;
	handleLineItem: (configurationId: string, newLineItem: models["PendingLineItemViewModel"]) => void;
}

const AddLineItem = ({ addItemVisibility, configuration, index, handleLineItem }: AddNewLineItemModalProps) => {
	const [childError, setChildError] = useState(false);
	const [quantityValue, setQuantityValue] = useState(1);
	const [saveButtonClick, setSaveButtonClick] = useState(false);
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const { control, handleSubmit, trigger, formState, register, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange"
	});
	const formFieldData = { formState, register, schema };

	const [modification, setModification] = useState<models["ModificationViewModel"][]>([]);
	const dimensions = useRef<Record<string, number | null>>({});
	const [submitted, setSubmitted] = useState(false);
	const [requiredDimensionsValues, setRequiredDimensionsValues] = useState<models["PendingValueViewModel"][]>([]);

	const param = configuration.globals?.productLine?.code ?? "";
	const { data: newLineItems, isLoading } = useGetLineItemsQuery(param, { skip: !param });

	const foundSearch = useMemo(() => {
		return newLineItems?.find((item) => `${item.sku} - ${item.description}`.includes(searchValue!));
	}, [newLineItems, searchValue]);

	useEffect(() => {
		if (foundSearch?.requiredDimensions) {
			foundSearch.requiredDimensions.forEach((dimension) => {
				dimensions.current[dimension.id ?? ""] = null;
			});
		}
	}, [foundSearch?.requiredDimensions]);

	useEffect(() => {
		const handleQuickKeys = (event: any) => {
			if (event.key === "\\") {
				event.preventDefault();
				trigger().then((valid) => {
					valid && handleSaveAction();
				});
			}
		};
		document.addEventListener("keydown", handleQuickKeys);

		return () => {
			document.removeEventListener("keydown", handleQuickKeys);
		};
	});

	const handleDraftSearch = (_event: SyntheticEvent, values: string | null) => {
		setSearchValue(values ?? null);
	};

	const quantityValues = Array.from({ length: 99 }, (_, i) => i + 1);
	const quantity = quantityValues.join().split(",");

	const newLineItemMap = useMemo(() => {
		if (!isLoading) {
			return newLineItems?.map((item) => {
				return `${item.sku} - ${item.description}`;
			});
		}
	}, [isLoading, newLineItems]);
	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
	};

	const handleQuantityChange = (_event: SyntheticEvent, value: number) => {
		setQuantityValue(value);
	};

	const saveButtonClicked = () => {
		setSaveButtonClick(true);
	};

	const handleDimensionChange = (id: any, value: number, description: string | null) => {
		dimensions.current[id] = value;

		const requiredDimensions: models["PendingValueViewModel"] = {
			description: description,
			value: value
		};

		setRequiredDimensionsValues((prev) => {
			return [...prev, requiredDimensions];
		});
	};

	const handleSaveAction = () => {
		setSubmitted(true);
		const { productSelect } = getValues();

		const searchSku = productSelect?.split("-")[0];
		const searchDescription = productSelect?.split(" - ")[1];

		if (
			searchValue !== null &&
			requiredDimensionsValues.length === foundSearch?.requiredDimensions.length &&
			!childError
		) {
			const newLineItem: models["PendingLineItemViewModel"] = {
				description: searchDescription ?? null,
				lineItemNumber: "1",
				sku: searchSku ?? null,
				modifications: modification,
				quantityOrdered: quantityValue,
				requiredDimensions: requiredDimensionsValues
			};

			handleLineItem(configuration?.configurationId ?? "", newLineItem);
			setSearchValue(null);
			setQuantityValue(1);

			if (saveButtonClick) {
				setSearchValue(null);
				addItemVisibility();
			}
		}
	};

	const handleChildData = (modification: models["PendingLineItemViewModel"][]) => {
		setModification(modification);
	};

	const handleChildValidation = (error: boolean) => {
		setChildError(error);
	};

	const containerRef = useRef<HTMLFormElement | null>(null);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<form
			ref={containerRef}
			onSubmit={(event) => {
				event.stopPropagation();
				handleSubmit(handleSaveAction)(event);
			}}
			noValidate
		>
			<AddLineItemContainer>
				<div data-testId="add-line-item-header">{AddLineItemHeader}</div>

				<div data-testId="add-line-item-required">{AddLineItemRequired}</div>

				<AddLineItemInputFormControl>
					<Controller
						name="productSelect"
						control={control}
						render={({ field: { onChange, value = null }, fieldState: { error } }) => (
							<div>
								<AutoComplete
									dataTestId="add-line-item-product-search"
									options={stringArraySortAlphabetically(newLineItemMap) ?? []}
									value={searchValue ?? ""}
									onChange={(event, values) => {
										handleDraftSearch(event, values ?? null);
										onChange(values);
									}}
									isLoading={isLoading}
									onMouseDownCapture={handleMouseDownCapture}
									defaultValue={quantityValue}
									isError={Boolean(error)}
									errorText={error?.message}
									label={AddLineItemProductPlaceholder}
									required
									disableClearable
									autoFocus
									noOptionsText={AddLineItemInvalidProductError}
								/>
							</div>
						)}
					/>

					<AutoComplete
						{...getFormFieldProps({
							name: "productSelectQuantity",
							...formFieldData
						})}
						options={quantity}
						value={quantityValue}
						onChange={handleQuantityChange}
						onMouseDownCapture={handleMouseDownCapture}
						defaultValue={quantityValue}
						isError={false}
						errorText=""
						label={AddLineItemQuantityPlaceholder}
						dataTestId="add-line-item-quantity"
						required
						disableClearable
					/>
				</AddLineItemInputFormControl>

				{foundSearch && searchValue && (
					<>
						<AddLineItemStandardContainer>
							<div data-testId="add-line-item-standard-dimensions-header">
								{AddLineItemStandardDimensionsHeader}
							</div>
							<AddLineItemStandardDimensions>
								<div data-testId="add-line-item-standard-dimensions-depth">
									<span>{AddLineDepthLabel}</span>
									<span>{foundSearch.standardDepth}</span>
								</div>
								<div data-testId="add-line-item-standard-dimensions-height">
									<span>{AddLineHeightLabel}</span>
									<span>{foundSearch.standardHeight}</span>
								</div>
								<div data-testId="add-line-item-standard-dimensions-width">
									<span>{AddLineWidthLabel}</span>
									<span>{foundSearch.standardWidth}</span>
								</div>
							</AddLineItemStandardDimensions>
						</AddLineItemStandardContainer>

						{foundSearch?.requiredDimensions?.length !== 0 && (
							<h1 data-testid="add-line-item-dimension-header">{SelectRequiredDimensions}</h1>
						)}

						<DimensionContainer>
							{foundSearch?.requiredDimensions?.map((dimension) => {
								return (
									dimension && (
										<div key={index}>
											<AutoComplete
												key={dimension.id}
												options={calculateIntervals(
													dimension.minValue,
													dimension.maxValue,
													dimension.increment
												)}
												onMouseDownCapture={(e: SyntheticEvent) => {
													if (
														e.target instanceof HTMLElement &&
														e.target.nodeName === "INPUT"
													) {
														e.stopPropagation();
													}
												}}
												onChange={(_, value) =>
													handleDimensionChange(dimension.id, value, dimension.description)
												}
												isLoading={false}
												label={dimension.description ?? ""}
												dataTestId="add-line-item-dimensions"
												getOptionLabel={(option) => String(option)}
												required
												isError={submitted && !dimensions.current[dimension.id ?? ""]}
												errorText={RequiredDimensionError}
												disableClearable
											/>

											{(!submitted || dimensions.current[dimension.id ?? ""]) && (
												<RequiredDimensionsValues data-testid="add-line-item-dimension-mix-max">
													{`Min: ${dimension.minValue}", Max: ${dimension.maxValue}"`}
												</RequiredDimensionsValues>
											)}
										</div>
									)
								);
							})}
						</DimensionContainer>
					</>
				)}

				{foundSearch?.allowModifications && (
					<div>
						<AddModification
							saveButtonClick={submitted}
							onValidation={handleChildValidation}
							onModificationChange={handleChildData}
							foundSearch={foundSearch}
						/>
					</div>
				)}

				<AddLineItemButtonWrapper>
					<div>
						<Button
							data-testId="add-line-item-cancel-button"
							variant="text"
							onClick={() => addItemVisibility()}
						>
							{AddLineCancelButton}
						</Button>
					</div>

					<div>
						<Button
							data-testId="add-line-item-save-button"
							variant="outlined"
							type="submit"
							onClick={saveButtonClicked}
						>
							{AddLineSaveButton}
						</Button>
						<div>{AddLineEnterText}</div>
					</div>

					<div>
						<Button
							data-testId="add-line-item-add-another-button"
							variant="contained"
							type="submit"
						>
							{AddLineAddAnotherItemButton}
						</Button>
						<div>{AddLineAddAnotherTextOne}</div>
					</div>
				</AddLineItemButtonWrapper>
			</AddLineItemContainer>
		</form>
	);
};

export default AddLineItem;
