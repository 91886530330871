import styled from "@emotion/styled";
import { Button } from "@mui/material";
import theme from "../../../styles/themes/variants/defaultTheme";
import { LinkButton } from "../../Common/Link";

export const AddModificationButton = styled(Button)({
	display: "flex",
	gap: "8px",
	fontWeight: 500,
	textTransform: "uppercase",
	marginLeft: "-8px"
});

export const AddModificationWrapper = styled.div(({ theme }) => ({
	div: {
		"&:first-child": {
			marginBottom: 8
		}
	}
}));

export const AddModificationTypeWrapper = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: 16,
	div: {
		"&:first-child": {
			flex: "130%"
		},
		"&:nth-child(2)": {
			flex: "30%"
		},
		"&:nth-child(3)": {
			flex: "10%"
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		div: {
			"&:first-child": {
				marginBottom: 0
			},
			"&:nth-child(2)": {
				marginBottom: 8
			},
			"&:nth-child(3)": {
				marginBottom: 8
			}
		}
	}
}));

export const AddModificationRemoveButton = styled(LinkButton)({
	color: theme.project.colors.fontColors.main,
	marginTop: "-24px",
	textDecorationColor: theme.project.colors.fontColors.main
});

export const AddModificationValues = styled.div(({ theme }) => ({
	color: "#000000",
	opacity: 0.6,
	[theme.breakpoints.up("md")]: {
		marginTop: "-10px"
	}
}));
