import AddIcon from "@mui/icons-material/Add";
import { ProductViewModel } from "data/api/v1";
import { SyntheticEvent, useState, useMemo, useEffect } from "react";
import {
	AddModificationButton,
	AddModificationWrapper,
	AddModificationTypeWrapper,
	AddModificationRemoveButton,
	AddModificationValues
} from "./AddModificationStyles.ts";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { useGetModificationsQuery } from "features/api/newOrderApi";
import {
	AddModificationButtonText,
	AddModificationHeader,
	AddModificationIncrementError,
	AddModificationPlaceholder,
	AddModificationRemoveText
} from "./constants.ts";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store.tsx";
import { calculateIntervals } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { models } from "../../../types/api/viewModels.ts";
import { v4 as uuidv4 } from "uuid";

interface AddModificationProps {
	onModificationChange: (modification: models["PendingLineItemViewModel"][]) => void;
	foundSearch: ProductViewModel | undefined;
	onValidation: (error: boolean) => void;
	saveButtonClick: boolean;
}

type DisplayModification = models["PendingModificationViewModel"] & {
	uuid?: string;
};

const AddModification = ({
	onModificationChange,
	foundSearch,
	onValidation,
	saveButtonClick
}: AddModificationProps) => {
	const [error, setError] = useState<boolean>(false);
	const [incrementValue, setIncrementValue] = useState(0);
	const [selectedMod, setSelectedMod] = useState<Record<string, models["ModificationViewModel"] | undefined | null>>(
		{}
	);

	const [modificationList, setModificationList] = useState<DisplayModification[]>([]);

	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const csvProductLines = parsedCSV?.configurations?.map((config) => config.globals?.productLine?.id);

	const newGlobalAttributes = useSelector((state: RootState) => state.newOrder.newGlobalAttributes);
	const globalAttributeProductLines = newGlobalAttributes?.globals?.productLine?.id;

	const { data: modifications, isLoading } = useGetModificationsQuery({
		productId: foundSearch?.id,
		productLineId: globalAttributeProductLines ?? csvProductLines
	});

	const handleModificationChange = (_event: SyntheticEvent, value: string, index: number) => {
		const findSelectedMod = modifications?.find((item) => `${item.sku} - ${item.description}`.includes(value));

		setSelectedMod((prev) => ({
			...prev,
			[findSelectedMod?.id ?? ""]: findSelectedMod
		}));

		const newModification: DisplayModification = {
			sku: findSelectedMod?.sku,
			lineItemNumber: "",
			quantityOrdered: 1,
			description: findSelectedMod?.description,
			id: findSelectedMod?.id,
			itemKey: findSelectedMod?.sku,
			uuid: modificationList[index]?.uuid ?? uuidv4()
		};

		setModificationList((prev) => {
			const newModificationList = prev.map((mod, modIndex) => {
				if (modIndex === index) {
					return newModification;
				}
				return mod;
			});

			onModificationChange(
				newModificationList.map((mod) => {
					const newMod = { ...mod };
					delete newMod.uuid;
					return newMod;
				})
			);
			return newModificationList;
		});
	};

	const handleRemoveModification = (index: number) => {
		const list = [...modificationList];
		list.splice(index, 1);
		setModificationList(list);
		onModificationChange(list);
	};

	const modificationsData = useMemo(() => {
		if (!isLoading) {
			return modifications?.map((item) => {
				return `${item.sku} - ${item.description}`;
			});
		}
	}, [isLoading, modifications]);

	const handleIncrementChange = (index: number, value: number, description: string) => {
		setIncrementValue(value);

		const newModification: DisplayModification = {
			...modificationList[index],
			values: [
				{
					description: description,
					value: value
				}
			]
		};

		setModificationList((prev) => {
			const newModificationList = prev.map((mod, modIndex) => (modIndex === index ? newModification : mod));
			onModificationChange(newModificationList);
			return newModificationList;
		});
	};

	const modificationTypeErrorState = modificationList.map((modification) => modification.description === "");

	useEffect(() => {
		if (modificationTypeErrorState.includes(true)) {
			setError(true);
			onValidation(error);
		} else {
			setError(false);
			onValidation(error);
		}
	}, [modificationTypeErrorState, error, onValidation]);

	const addServiceList = () => {
		setModificationList([
			...modificationList,
			{
				sku: "",
				lineItemNumber: "",
				quantityOrdered: 1,
				description: "",
				id: "",
				itemKey: "",
				values: null,
				uuid: uuidv4()
			}
		]);
	};

	return (
		<AddModificationWrapper>
			{modificationList.length === 0 && (
				<div>
					<AddModificationButton
						variant="text"
						data-testid="add-line-item-modification-button"
						onClick={addServiceList}
					>
						<AddIcon />
						{AddModificationButtonText}
					</AddModificationButton>
				</div>
			)}

			{modificationList.map((singleMod, index) => (
				<div key={singleMod.uuid}>
					{index === 0 && <div>{AddModificationHeader}</div>}

					<AddModificationTypeWrapper>
						<AutoComplete
							options={modificationsData ?? []}
							onChange={(event, value) => handleModificationChange(event, value, index)}
							required
							isError={saveButtonClick && modificationTypeErrorState.includes(true)}
							errorText={AddModificationIncrementError}
							label={AddModificationPlaceholder}
							dataTestId="add-line-item-modification-select"
							disableClearable
						/>

						{selectedMod[singleMod?.id ?? ""]?.ranges?.map((range: any, rangeIndex: number) => (
							<div key={rangeIndex}>
								<AutoComplete
									options={calculateIntervals(range.minValue, range.maxValue, range.increment)}
									onChange={(_, value) => handleIncrementChange(index, value, range.description)}
									isError={incrementValue === 0}
									errorText={AddModificationIncrementError}
									label={range.description ? `Select ${range.description}` : "Select a value"}
									dataTestId="add-line-item-modification-range-select"
									disableClearable
									required
								/>

								{incrementValue !== 0 && (
									<AddModificationValues data-testid="add-line-item-modification-range-value">
										{`Min: ${range.minValue}", Max: ${range.maxValue}"`}
									</AddModificationValues>
								)}
							</div>
						))}

						<AddModificationRemoveButton
							data-testid="add-line-item-modification-remove-button"
							onClick={() => handleRemoveModification(index)}
						>
							{AddModificationRemoveText}
						</AddModificationRemoveButton>
					</AddModificationTypeWrapper>

					{modificationList.length - 1 === index && (
						<div>
							<AddModificationButton
								variant="text"
								data-testid="add-line-item-modification-button"
								onClick={addServiceList}
							>
								<AddIcon />
								{AddModificationButtonText}
							</AddModificationButton>
						</div>
					)}
				</div>
			))}
		</AddModificationWrapper>
	);
};

export default AddModification;
