import { FormHelperText, TextField } from "@mui/material";
import {
	JobNameOptionalText,
	PONumberText,
	ReplacementReviewOrderDetailsHeader,
	RequiredFieldsText
} from "constants/text";
import { Controller, useFormContext } from "react-hook-form";
import {
	JOB_NAME_MAX_LENGTH,
	PO_NUMBER_MAX_LENGTH,
	schema
} from "components/NewOrders/NewOrderShippingDetailsForm/schema";
import { getFormFieldProps } from "utils/form";
import { ShippingDetailsCard } from "./NewOrders.styles";
import { useEffect, useMemo, useState } from "react";
import {
	DesignerIsNotHereText,
	EnterDesignerHelperText,
	NewOrderDesignerNumberText,
	NewOrderEnterDesignerNumberText,
	OptionalText,
	RequiredText
} from "./constants";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { useGetUserDetailsQuery } from "features/api/userApi";
import { models } from "types/api/viewModels";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder";
import { DesignerViewModel } from "data/api/v1";
import { sortDesigners } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { displayDesigner } from "utils/order";

export interface ShippingOrderDetailsProp {
	designers?: models["DesignerViewModel"][];
	defaultDesigner?: models["DesignerViewModel"];
}

const ShippingOrderDetails = ({ designers, defaultDesigner }: ShippingOrderDetailsProp) => {
	const { formState, register, watch, control, setValue, clearErrors } = useFormContext();
	const { errors } = formState;
	const formFieldData = { formState, register, schema };
	const poNumberInput = watch("poNumber") ?? "";
	const jobNameInput = watch("jobName") ?? "";
	const designer = watch("designer");
	const { data: user } = useGetUserDetailsQuery();
	const reduxCustomDesigner = useSelector((state: RootState) => state.submittingNewOrder.designer?.customDesigner);
	const reduxDesigner = useSelector((state: RootState) => state.submittingNewOrder.designer?.designer);
	const dispatch = useDispatch();

	const designerOptions = useMemo(() => {
		let newDesignerList = [...(designers || [])];
		if (defaultDesigner) {
			newDesignerList.push(defaultDesigner);
		}
		return sortDesigners(
			newDesignerList
				.filter((designer) => designer.designerNumber != null)
				.filter(
					(item: DesignerViewModel, index: number, arr: (DesignerViewModel | string)[]) =>
						arr.indexOf(item) === index
				)
		);
	}, [designers, defaultDesigner]);

	useEffect(() => {
		if (designer === DesignerIsNotHereText || reduxCustomDesigner) {
			setValue("designer", DesignerIsNotHereText);
			if (reduxCustomDesigner) {
				setValue("customDesigner", reduxCustomDesigner);
			}
		} else {
			clearErrors("customDesigner");
		}
	}, [setValue, designer, clearErrors, dispatch, reduxCustomDesigner]);

	const [designerDefaultValue, setDesignerDefaultValue] = useState<DesignerViewModel | string | undefined | null>();

	useEffect(() => {
		const userDesignerNumber = designerOptions.find(
			(designer) => user?.user?.designerNumber && designer?.designerNumber?.includes(user?.user?.designerNumber)
		);
		const handleDesignerDefault = (designerDefault: string | DesignerViewModel | undefined) => {
			setDesignerDefaultValue(designerDefault);
			setValue("designer", designerDefault);
			dispatch(submittingNewOrderActions.updateDesignerNumber(designerDefault));
		};
		if (reduxDesigner) {
			handleDesignerDefault(reduxDesigner);
		} else if (defaultDesigner) {
			handleDesignerDefault(defaultDesigner);
		} else if (designerOptions.length === 1) {
			handleDesignerDefault(designerOptions[0]);
		} else if (userDesignerNumber) {
			handleDesignerDefault(userDesignerNumber);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [designerOptions, user?.user?.designerNumber, defaultDesigner, setValue, dispatch]);

	// Below code allows the DesignerIsNotHereText to ALWAYS appear as an option if it is in the original option list
	const filterOptions = (options: (DesignerViewModel | string)[], { inputValue }: { inputValue: string }) => {
		const filtered = options.filter(
			(option) =>
				typeof option !== "string" && displayDesigner(option).toUpperCase().includes(inputValue.toUpperCase())
		);
		if (!filtered.includes(DesignerIsNotHereText) && options.includes(DesignerIsNotHereText)) {
			filtered.push(DesignerIsNotHereText);
		}
		return filtered;
	};

	return (
		<ShippingDetailsCard isCustomDesigner={designer === DesignerIsNotHereText}>
			<div data-testid="new-orders-shipping-order-details-header">{ReplacementReviewOrderDetailsHeader}</div>
			<div>{RequiredFieldsText}</div>
			<div>
				<Controller
					name="designer"
					control={control}
					defaultValue={designerDefaultValue}
					render={({ field: { onChange, value = designerDefaultValue ?? null }, fieldState: { error } }) => (
						<AutoComplete
							{...getFormFieldProps({
								name: "designer",
								...formFieldData
							})}
							options={Array.from(new Set([...designerOptions, DesignerIsNotHereText]))}
							onChange={(_event, value) => {
								if (value !== DesignerIsNotHereText) {
									dispatch(submittingNewOrderActions.updateCustomDesignerNumber(undefined));
									setValue("customDesigner", undefined);
									dispatch(submittingNewOrderActions.updateDesignerNumber(value));
								}
								onChange(value);
							}}
							getOptionLabel={(option: DesignerViewModel | string) => {
								if (typeof option === "string") {
									return option;
								} else {
									return `${option.firstName ? option.firstName + " " : ""}${option.lastName ? option.lastName + " " : ""}${option.firstName || option.lastName ? "- " : ""}${option.designerNumber}`;
								}
							}}
							filterOptions={filterOptions}
							value={value}
							isError={!!error}
							errorText={error?.message}
							label={`${NewOrderDesignerNumberText} ${designer === DesignerIsNotHereText ? RequiredText : OptionalText}`}
							dataTestId="shipping-order-details-designer"
						/>
					)}
				/>
				{designer === DesignerIsNotHereText && (
					<div>
						<TextField
							{...getFormFieldProps({
								name: "customDesigner",
								...formFieldData
							})}
							label={NewOrderEnterDesignerNumberText}
							data-testid="shipping-order-details-customDesigner"
							inputProps={{ maxLength: 6 }}
						/>
						{!errors.customDesigner?.message && <FormHelperText>{EnterDesignerHelperText}</FormHelperText>}
					</div>
				)}
			</div>
			<div>
				<TextField
					{...getFormFieldProps({
						name: "poNumber",
						characterLimit: {
							currentLength: poNumberInput.length,
							limit: PO_NUMBER_MAX_LENGTH
						},
						...formFieldData
					})}
					data-testid="shipping-order-details-poNumber"
					fullWidth
					label={PONumberText}
					size="small"
				/>
				<TextField
					{...getFormFieldProps({
						name: "jobName",
						characterLimit: {
							currentLength: jobNameInput.length,
							limit: JOB_NAME_MAX_LENGTH
						},
						...formFieldData
					})}
					fullWidth
					data-testid="shipping-order-details-jobName"
					label={JobNameOptionalText}
					size="small"
				/>
			</div>
		</ShippingDetailsCard>
	);
};

export default ShippingOrderDetails;
