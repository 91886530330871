import { PropsWithChildren, SyntheticEvent, useEffect, useMemo, useState } from "react";
import {
	AccountBillToContentWrapper,
	AccountBillToLogo,
	EmptyMobileBlock,
	FileContentsContainer,
	FileContentsLogo,
	SummaryProductLine
} from "./NewOrders.styles";
import {
	DoorStyleText,
	FileNameText,
	FinishText,
	SelectAccountText,
	SelectBillToText,
	noAccountText
} from "constants/text";
import { Button, FormControl, PopperProps } from "@mui/material";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { brandCheck } from "utils/order";
import { useDispatch, useSelector } from "react-redux";
import { NewOrderRemoveGroup, NewOrderSelectAccountError, NewOrderSelectBillToError } from "./constants";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { AutoCompletePopper } from "components/Common/Autocomplete/Autocomplete.styles";
import { convertToTitleCase } from "utils/string";
import DeleteIcon from "@mui/icons-material/Delete";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import GlobalFormProvider from "./AddGlobalAttributes/forms/GlobalFormProvider";
import AddGlobalAttributes from "./AddGlobalAttributes/AddGlobalAttributes";
import { ImportOrderResponseData } from "types/api/orders/importOrder";
import { models } from "types/api/viewModels.ts";
import { RootState } from "stores/application.store.tsx";
import { type ConfigurationGroup, sortedAccounts, sortedBillTos } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { GetAccountsResponseData } from "types/api/accounts/getAccounts.ts";

const runForAllConfigs = (
	csvContents: ImportOrderResponseData[] | undefined,
	importedCSVs: ImportOrderResponseData | undefined,
	callback: (reduxConfigIndex: number, configIndex: number) => void
) => {
	csvContents?.forEach((csv) =>
		csv?.configurations?.forEach((config, configIndex) => {
			const reduxConfigIndex =
				importedCSVs?.configurations?.findIndex(
					(importedCSV) => importedCSV.configurationId === config.configurationId
				) ?? -1;
			if (~reduxConfigIndex) {
				callback(reduxConfigIndex, configIndex);
			}
		})
	);
};

const findAccount = (accounts: GetAccountsResponseData | undefined, accountNumber: string | null | undefined) =>
	accounts?.find((account) => account?.accountNumber === accountNumber);

const findBillTo = (
	selectedAccount: models["CustomerAccountViewModel"] | null | undefined,
	billToId: string | null | undefined
) => selectedAccount?.billTos?.find((billTo) => billTo.billToId === billToId);

interface AccountBillToContentProps extends PropsWithChildren {
	csvContents: ImportOrderResponseData[] | undefined;
	productLine: string;
	configGroup?: ConfigurationGroup;
	index: number;
	handleRemoveGroup?: () => void;
	isBuildOrderPage?: boolean;
	updateButtons?: boolean;
	handleUpdateAccount?: (account: models["CustomerAccountViewModel"] | null, index: number) => void;
	handleUpdateBillTo?: (billTo: models["BillToViewModel"] | null | undefined, index: number) => void;
	handleConfigurationUpdate?: (configuration: models["PendingOrderConfigurationViewModel"]) => void;
	setConfigurationToUpdate?: () => void;
}

const AccountBillToContent = ({
	children,
	csvContents,
	productLine,
	configGroup,
	index,
	handleRemoveGroup,
	handleUpdateBillTo,
	handleUpdateAccount,
	isBuildOrderPage,
	updateButtons,
	handleConfigurationUpdate,
	setConfigurationToUpdate
}: AccountBillToContentProps) => {
	const importedCSVs = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const productLineAccountInfo = useSelector((state: RootState) => state.newOrder.productLineAccounts);
	const accounts = useMemo(() => productLineAccountInfo[productLine], [productLine, productLineAccountInfo]);
	const dispatch = useDispatch();
	const [groupAccountNumber, setGroupAccountNumber] = useState<models["CustomerAccountViewModel"] | null | undefined>(
		findAccount(accounts, configGroup?.accountNumber)
	);
	const [groupBillToId, setGroupBillToId] = useState<models["BillToViewModel"] | null | undefined>(
		findBillTo(groupAccountNumber, configGroup?.billToId)
	);

	useEffect(() => {
		const hasValidAccount =
			csvContents?.every((csv) => csv?.configurations?.every((config) => config.accountNumber !== null)) ?? true;
		const hasValidBillTo =
			csvContents?.every((csv) => csv?.configurations?.every((config) => config.billToId !== null)) ?? true;

		if (!hasValidAccount) setGroupAccountNumber(null);
		if (!hasValidBillTo) setGroupBillToId(null);
	}, [csvContents]);

	const handleAccountChange = (_event: SyntheticEvent, value: models["CustomerAccountViewModel"]) => {
		setGroupAccountNumber(value);
		runForAllConfigs(csvContents, importedCSVs, (reduxConfigIndex, configIndex) => {
			dispatch(newOrderActions.updateAccountNumber({ index: reduxConfigIndex, newAccountNumber: value }));
			dispatch(
				submittingNewOrderActions.updateDraftAccountNumber({
					index: reduxConfigIndex,
					newAccountNumber: value
				})
			);
			handleUpdateAccount?.(value, configIndex);
		});

		if (groupBillToId !== null) {
			setGroupBillToId(undefined);
			runForAllConfigs(csvContents, importedCSVs, (reduxConfigIndex, configIndex) => {
				dispatch(newOrderActions.updateBillToNumber({ index: reduxConfigIndex, newBillToNumber: undefined }));
				dispatch(
					submittingNewOrderActions.updateDraftBillToNumber({
						index: reduxConfigIndex,
						newBillToNumber: undefined
					})
				);
				handleUpdateBillTo?.(undefined, configIndex);
			});
		}
	};

	const handleBillToChange = (_event: SyntheticEvent, value: models["BillToViewModel"]) => {
		if (!groupAccountNumber) {
			setGroupAccountNumber(null);
			runForAllConfigs(csvContents, importedCSVs, (reduxConfigIndex, configIndex) => {
				dispatch(newOrderActions.updateAccountNumber({ index: reduxConfigIndex, newAccountNumber: null }));
				dispatch(
					submittingNewOrderActions.updateDraftAccountNumber({
						index: reduxConfigIndex,
						newAccountNumber: null
					})
				);
				handleUpdateAccount?.(null, configIndex);
			});
		} else {
			setGroupBillToId(value);
			runForAllConfigs(csvContents, importedCSVs, (reduxConfigIndex, configIndex) => {
				dispatch(newOrderActions.updateBillToNumber({ index: reduxConfigIndex, newBillToNumber: value }));
				dispatch(
					submittingNewOrderActions.updateDraftBillToNumber({
						index: reduxConfigIndex,
						newBillToNumber: value
					})
				);
				handleUpdateBillTo?.(value, configIndex);
			});
		}
	};

	const CustomPopper = (props: PopperProps) => {
		if (!groupAccountNumber) {
			return null;
		}
		return <AutoCompletePopper {...props} />;
	};

	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
		if (!groupAccountNumber) {
			dispatch(newOrderActions.updateAccountNumber({ index: index, newAccountNumber: null }));
			dispatch(submittingNewOrderActions.updateDraftAccountNumber({ index: index, newAccountNumber: null }));
		}
	};

	const selectedAccount: models["CustomerAccountViewModel"] | undefined = useMemo(
		() => findAccount(accounts, groupAccountNumber?.accountNumber),
		[accounts, groupAccountNumber]
	);
	const selectedBillTo = useMemo(
		() => findBillTo(selectedAccount, groupBillToId?.billToId),
		[groupBillToId?.billToId, selectedAccount]
	);
	const getConfigs = (configurations: models["PendingOrderConfigurationViewModel"][] | null | undefined) =>
		isBuildOrderPage && configurations?.[0] ? [configurations[0]] : configurations;

	return (
		<AccountBillToContentWrapper
			isBuildOrderPage={isBuildOrderPage}
			isBillToError={groupBillToId === null}
		>
			{csvContents?.map((csv) =>
				getConfigs(csv?.configurations)?.map((configuration, index) => (
					<FileContentsContainer
						key={configuration.configurationId}
						isBuildOrderPage={isBuildOrderPage}
					>
						{index === 0 ? (
							<FileContentsLogo isBuildOrderPage={isBuildOrderPage}>
								<>
									<AccountBillToLogo
										parentBrand={configuration?.globals?.brand?.code}
										className="body2"
										src={
											brandCheck(configuration?.globals?.brand?.code)
												? `/assets/manufacture_logos/${configuration?.globals?.brand?.code}.png`
												: "/assets/tandem_logos/cwg_logo.png"
										}
										alt={
											brandCheck(configuration?.globals?.brand?.code)
												? (configuration?.globals?.brand?.description ?? "")
												: "CabinetworksGroup Logo"
										}
									/>
									<SummaryProductLine
										data-testid="product-line"
										fontVariant={isBuildOrderPage ? "small" : "large"}
									>
										{configuration?.globals?.productLine?.description}
									</SummaryProductLine>
								</>
							</FileContentsLogo>
						) : (
							<EmptyMobileBlock />
						)}

						{!isBuildOrderPage && (
							<>
								{index === 0 ? (
									<div className="subtitle2">
										{FileNameText}
										<div
											className="body2"
											data-testid="uploaded-file-name"
										>
											{csv?.fileName}
										</div>
									</div>
								) : (
									<EmptyMobileBlock />
								)}
								{csv?.configurations?.length && (
									<div className="subtitle2">
										{DoorStyleText}
										<div
											className="body2"
											data-testid="door-style"
										>
											{configuration?.globals?.style?.description}
										</div>
									</div>
								)}
								{csv?.configurations?.length && (
									<div className="subtitle2">
										{FinishText}
										<div
											className="body2"
											data-testid="finish"
										>
											{configuration?.globals?.finish?.description}
										</div>
									</div>
								)}
							</>
						)}
					</FileContentsContainer>
				))
			)}
			<FormControl
				size="small"
				required
				fullWidth
				sx={{ alignSelf: "flex-start" }}
			>
				<AutoComplete
					options={sortedAccounts(accounts) ?? []}
					onMouseDownCapture={(e: SyntheticEvent) => {
						if (e.target instanceof HTMLElement && e.target.nodeName === "INPUT") {
							e.stopPropagation();
						}
					}}
					getOptionLabel={(option) =>
						option?.accountNumber && option?.accountName
							? `${option.accountNumber} - ${option.accountName}`
							: (option?.accountNumber ?? "")
					}
					onChange={handleAccountChange}
					value={selectedAccount}
					isError={Boolean(accounts) && (!accounts || accounts.length === 0 || groupAccountNumber === null)}
					errorText={!accounts || accounts.length === 0 ? noAccountText : NewOrderSelectAccountError}
					label={SelectAccountText}
					dataTestId="newOrder-select-account"
					required
					disableClearable
				/>
			</FormControl>

			<FormControl
				size="small"
				required
				fullWidth
				sx={{ alignSelf: "flex-start" }}
			>
				<AutoComplete
					key={selectedAccount?.accountNumber}
					options={sortedBillTos(selectedAccount?.billTos) ?? []}
					onMouseDownCapture={handleMouseDownCapture}
					getOptionLabel={(option: models["BillToViewModel"]) => {
						const projectName =
							option.projectName && option.projectName.trim() !== "" ? option.projectName + " - " : "";
						const logisticsMode = option.logisticsMode
							? " - " + convertToTitleCase(option.logisticsMode)
							: "";
						return option
							? `${projectName}${option.city}, ${option.state}${logisticsMode} - ${option.billToId}`
							: "";
					}}
					onChange={handleBillToChange}
					value={selectedBillTo}
					isError={groupBillToId === null}
					errorText={NewOrderSelectBillToError}
					label={SelectBillToText}
					dataTestId="newOrder-select-billTo"
					PopperComponent={CustomPopper}
					required
					disableClearable
				/>
			</FormControl>

			{updateButtons && (
				<>
					<GlobalFormProvider isUpdate>
						<AddGlobalAttributes
							setConfigurationToUpdate={setConfigurationToUpdate}
							handleConfigurationUpdate={handleConfigurationUpdate}
							isUpdate
						/>
					</GlobalFormProvider>
					<Button
						onClick={handleRemoveGroup}
						variant="text"
						data-testid="remove-group"
					>
						<DeleteIcon />
						{NewOrderRemoveGroup}
					</Button>
				</>
			)}
			{children}
		</AccountBillToContentWrapper>
	);
};

export default AccountBillToContent;
