import { NewOrderModalTracking, ImportConfigurationViewModel } from "data/api/v1";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { read } from "hooks/useSession.ts";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model.ts";
import { UserNavigationItemViewModel } from "data/api/v1/model/user-navigation-item-view-model.ts";
import { ImportOrderResponseData } from "types/api/orders/importOrder";
import { ValidateProductsResponseData } from "types/api/products/postValidate";
import { models } from "types/api/viewModels.ts";
import { GetAccountsResponseData } from "types/api/accounts/getAccounts.ts";
import { BillTosShipTosResponseData } from "types/api/accounts/billTosShipTos.ts";

export interface NewOrder {
	fileName: string | null;
	configurations: Array<ImportConfigurationViewModel> | null;
	fileUpload: Array<File>;
}

export interface FileContents {
	fileName: string | null;
	configurations: Array<ImportConfigurationViewModel> | null;
}

export interface LinkNavigation {
	navItem?: UserNavigationItemViewModel;
	index?: number;
	state: "proceed" | "pause";
}

const newOrder = read("_newOrder");

export interface NewOrderSlice {
	leaveNewOrderFlow: LinkNavigation | undefined;
	modalStatus: NewOrderModalTracking;
	newAddresses: ShipToViewModel[];
	parsedCSV: ImportOrderResponseData | undefined;
	validatedOrderResponse: ValidateProductsResponseData | undefined;
	newGlobalAttributes: models["PendingOrderConfigurationViewModel"] | undefined;
	updatedGlobalAttributes: models["PendingOrderConfigurationViewModel"] | undefined;
	productLineAccounts: Record<string, GetAccountsResponseData>;
}

export const initialState: NewOrderSlice = newOrder || {
	leaveNewOrderFlow: undefined,
	modalStatus: NewOrderModalTracking.NONE,
	newAddresses: [],
	parsedCSV: undefined,
	validatedOrderResponse: undefined,
	newGlobalAttribute: undefined,
	updatedGlobalAttributes: undefined,
	productLineAccounts: {}
};

const newOrderSlice = createSlice({
	name: "newOrder",
	initialState,
	reducers: {
		addNewAddress: (state, action) => {
			const addressMatchCallback = (shipTo: ShipToViewModel | null) => {
				return (
					shipTo?.address?.line1 === action.payload?.line1 &&
					shipTo?.address?.line2 === action.payload?.line2 &&
					shipTo?.address?.line3 === action.payload?.line3 &&
					shipTo?.address?.city === action.payload?.city &&
					shipTo?.address?.zip === action.payload?.zip
				);
			};

			if (action.payload && !state.newAddresses?.some(addressMatchCallback)) {
				state.newAddresses?.push(action.payload);
			}

			return state;
		},
		setModalState: (state, action) => {
			state.modalStatus = action.payload;
		},
		addParsedCSV: (state, action) => {
			state.parsedCSV = action.payload;
		},
		addGlobalAttributes: (state, action: PayloadAction<models["PendingOrderConfigurationViewModel"]>) => {
			state.newGlobalAttributes = action.payload;
		},
		updateGlobalAttributes: (state, action: PayloadAction<models["PendingOrderConfigurationViewModel"]>) => {
			state.updatedGlobalAttributes = { ...state.updatedGlobalAttributes, ...action.payload };
		},
		clearUpdateGlobalAttributes: (state) => {
			state.updatedGlobalAttributes = undefined;
		},
		updateAccountNumber: (
			state,
			action: PayloadAction<{
				index: number | undefined;
				newAccountNumber: models["CustomerAccountViewModel"] | null;
			}>
		) => {
			const { index, newAccountNumber } = action.payload;
			if (index !== undefined && state.parsedCSV?.configurations) {
				const configuration = state.parsedCSV.configurations[index];
				if (configuration) {
					configuration.accountId = newAccountNumber === null ? null : newAccountNumber?.accountId;
					configuration.accountName = newAccountNumber === null ? null : newAccountNumber?.accountName;
					configuration.accountNumber = newAccountNumber === null ? null : newAccountNumber?.accountNumber;
				}
			}
		},
		updateBillToNumber: (
			state,
			action: PayloadAction<{
				index: number | undefined;
				newBillToNumber: models["BillToViewModel"] | null | undefined;
			}>
		) => {
			const { index, newBillToNumber } = action.payload;
			if (index !== undefined && state.parsedCSV?.configurations) {
				const configuration = state.parsedCSV.configurations[index];
				if (configuration) {
					configuration.billToId = newBillToNumber === null ? null : newBillToNumber?.billToId;
				}
			}
		},
		addValidatedOrder: (state, action) => {
			state.validatedOrderResponse = action.payload;
		},
		clearNewOrder: (state) => {
			state.modalStatus = NewOrderModalTracking.NONE;
			state.newAddresses = [];
			state.parsedCSV = undefined;
			state.validatedOrderResponse = undefined;
			state.newGlobalAttributes = undefined;
			state.updatedGlobalAttributes = undefined;
		},
		leaveNewOrderFlow: (state, action) => {
			state.leaveNewOrderFlow = action.payload;
		},
		continueNewOrderFlow: (state) => {
			if (state.leaveNewOrderFlow) {
				state.leaveNewOrderFlow = { ...state.leaveNewOrderFlow, state: "proceed" };
			}
		},
		resetNewOrderFlow: (state) => {
			state.leaveNewOrderFlow = undefined;
		},
		addProductLineAccountInfo: (
			state,
			{ payload: [productLineCode, data] }: PayloadAction<[string, GetAccountsResponseData]>
		) => {
			state.productLineAccounts = {
				...state.productLineAccounts,
				[productLineCode]: data
			};
		},
		updateProductLineAccountInfoShipTos: (state, { payload }: PayloadAction<BillTosShipTosResponseData>) => {
			// Unused now but maybe useful later to update product line billTos with their shipTos
			if (state.productLineAccounts) {
				Object.entries(state.productLineAccounts).forEach(([productLineCode, accounts]) => {
					if (state.productLineAccounts[productLineCode]) {
						state.productLineAccounts[productLineCode] = accounts?.map((account) => ({
							...account,
							billTos: account.billTos?.map((billTo) => ({
								...billTo,
								shipTos: payload?.filter((shipTo) => shipTo?.billToId === billTo?.billToId) ?? []
							}))
						}));
					}
				});
			}
		}
	}
});

export const newOrderActions = newOrderSlice.actions;
export default newOrderSlice.reducer;
